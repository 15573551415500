import * as React from 'react'
import { Layout } from '../components/layout'
import { ContactForm } from '../components/contactForm'
import { Helmet } from 'react-helmet'

export default function ContactPage(props) {

	return (
		<Layout>
			<Helmet>
					<title>Contact | Bethany Rachelle</title>
				</Helmet>
			<ContactForm />
		</Layout>
	)
}