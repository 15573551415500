import * as React from 'react'
import * as styles from './contactForm.module.css'
import { VscArrowRight } from 'react-icons/vsc'

function ContactForm(props) {
	const [formResponse, setFormResponse]	= React.useState(false)

	function resizeTextarea(e) {
		console.log(e)
		e.target.style.height = `${e.target.scrollHeight}px`
	}

	function handleSubmit(e) {
		e.preventDefault()

		const contactForm = document.querySelector('#contact-form')
		const formData = new FormData(contactForm)

		for (let value of formData.values()) {
			console.log(value)
		}

		fetch('/', {
			method: 'POST',
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: new URLSearchParams(formData).toString()
		})
		.then((response) => {
			console.log('Response: ', response)
			setFormResponse(response.status)
		})
		.catch((error) => {
			console.log('Error: ', error)
		})
	}

	if (!formResponse) {
		return (
			<form 
				id='contact-form' 
				netlify-honeypot="bot-field" 
				data-netlify='true' 
				name='contact' 
				className={styles.contactForm} 
			>
				<input type='hidden' name='form-name' value='contact' />
				<input className={styles.name} id='name' type='text' required='true' name='name' placeholder='Name' /> 
				<input className={styles.email} id='email' type='email' required='true' name='email' placeholder='Email' />
				<textarea className={styles.message} id='message' required='true' name='message' placeholder='Message' onInput={resizeTextarea}/>
					<h2 className={styles.submitBtn} onClick={handleSubmit}>
						Send
						<VscArrowRight className={styles.submitBtnIcon} />
					</h2>
			</form>
		)
	} 
	
	if (formResponse === 200) {
		return (
			<h1 className={styles.statusMessage}>Your message sent successfully!</h1>
		)
	}
	
	return (
		<h1 className={styles.statusMessage}>Woops, there was an error!</h1>
	)
}

export { ContactForm }